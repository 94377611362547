import { Box, Modal, Typography } from "@mui/material";
import { Fragment, memo, useState } from "react";
import { Pressable } from "react-zoomable-ui";

const hashes = {
	0: "Front Sideline",
	1: "Front Hash",
	2: "Back Hash",
	3: "Back Sideline"
};

const colours = {
    "aliceblue":"#f0f8ff", "antiquewhite":"#faebd7", "aqua":"#00ffff", "aquamarine":"#7fffd4", "azure":"#f0ffff", "beige":"#f5f5dc", "bisque":"#ffe4c4", "black":"#000000", "blanchedalmond":"#ffebcd", "blue":"#0000ff", "blueviolet":"#8a2be2", "brown":"#a52a2a", "burlywood":"#deb887", "cadetblue":"#5f9ea0", "chartreuse":"#7fff00", "chocolate":"#d2691e", "coral":"#ff7f50", "cornflowerblue":"#6495ed", "cornsilk":"#fff8dc", "crimson":"#dc143c", "cyan":"#00ffff", "darkblue":"#00008b", "darkcyan":"#008b8b", "darkgoldenrod":"#b8860b", "darkgray":"#a9a9a9", "darkgreen":"#006400", "darkkhaki":"#bdb76b", "darkmagenta":"#8b008b", "darkolivegreen":"#556b2f", "darkorange":"#ff8c00", "darkorchid":"#9932cc", "darkred":"#8b0000", "darksalmon":"#e9967a", "darkseagreen":"#8fbc8f", "darkslateblue":"#483d8b", "darkslategray":"#2f4f4f", "darkturquoise":"#00ced1", "darkviolet":"#9400d3", "deeppink":"#ff1493", "deepskyblue":"#00bfff", "dimgray":"#696969", "dodgerblue":"#1e90ff", "firebrick":"#b22222", "floralwhite":"#fffaf0", "forestgreen":"#228b22", "fuchsia":"#ff00ff", "gainsboro":"#dcdcdc", "ghostwhite":"#f8f8ff", "gold":"#ffd700", "goldenrod":"#daa520", "gray":"#808080", "green":"#008000", "greenyellow":"#adff2f",
     "honeydew":"#f0fff0", "hotpink":"#ff69b4", "indianred ":"#cd5c5c", "indigo":"#4b0082", "ivory":"#fffff0", "khaki":"#f0e68c", "lavender":"#e6e6fa", "lavenderblush":"#fff0f5", "lawngreen":"#7cfc00", "lemonchiffon":"#fffacd", "lightblue":"#add8e6", "lightcoral":"#f08080", "lightcyan":"#e0ffff", "lightgoldenrodyellow":"#fafad2", "lightgrey":"#d3d3d3", "lightgreen":"#90ee90", "lightpink":"#ffb6c1", "lightsalmon":"#ffa07a", "lightseagreen":"#20b2aa", "lightskyblue":"#87cefa", "lightslategray":"#778899", "lightsteelblue":"#b0c4de", "lightyellow":"#ffffe0", "lime":"#00ff00", "limegreen":"#32cd32", "linen":"#faf0e6", "magenta":"#ff00ff", "maroon":"#800000", "mediumaquamarine":"#66cdaa", "mediumblue":"#0000cd", "mediumorchid":"#ba55d3", "mediumpurple":"#9370d8", "mediumseagreen":"#3cb371", "mediumslateblue":"#7b68ee",  "mediumspringgreen":"#00fa9a", "mediumturquoise":"#48d1cc", "mediumvioletred":"#c71585", "midnightblue":"#191970", "mintcream":"#f5fffa", "mistyrose":"#ffe4e1", "moccasin":"#ffe4b5", "navajowhite":"#ffdead", "navy":"#000080", "oldlace":"#fdf5e6", "olive":"#808000", "olivedrab":"#6b8e23", "orange":"#ffa500", "orangered":"#ff4500", "orchid":"#da70d6", "palegoldenrod":"#eee8aa",
     "palegreen":"#98fb98", "paleturquoise":"#afeeee", "palevioletred":"#d87093", "papayawhip":"#ffefd5", "peachpuff":"#ffdab9", "peru":"#cd853f", "pink":"#ffc0cb", "plum":"#dda0dd", "powderblue":"#b0e0e6", "purple":"#800080", "rebeccapurple":"#663399", "red":"#ff0000", "rosybrown":"#bc8f8f", "royalblue":"#4169e1", "saddlebrown":"#8b4513", "salmon":"#fa8072", "sandybrown":"#f4a460", "seagreen":"#2e8b57", "seashell":"#fff5ee", "sienna":"#a0522d", "silver":"#c0c0c0", "skyblue":"#87ceeb", "slateblue":"#6a5acd", "slategray":"#708090", "snow":"#fffafa", "springgreen":"#00ff7f", "steelblue":"#4682b4", "tan":"#d2b48c", "teal":"#008080", "thistle":"#d8bfd8", "tomato":"#ff6347", "turquoise":"#40e0d0", "violet":"#ee82ee", "wheat":"#f5deb3", "white":"#ffffff", "whitesmoke":"#f5f5f5", "yellow":"#ffff00", "yellowgreen":"#9acd32"
};

function pickfontcolor(bgColortext, lightColor, darkColor) {
    let bgColor = colours[bgColortext.toLowerCase()];
    var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
    var r = parseInt(color.substring(0, 2), 16); // hexToR
    var g = parseInt(color.substring(2, 4), 16); // hexToG
    var b = parseInt(color.substring(4, 6), 16); // hexToB
    var uicolors = [r / 255, g / 255, b / 255];
    var c = uicolors.map((col) => {
      if (col <= 0.03928) {
        return col / 12.92;
      }
      return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    var L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
    return (L > 0.179) ? darkColor : lightColor;
  }

function coordstotext(coords)
{
    
    let side = 0;
    if (coords['x']<80)
        side = 1;
    if (coords['x']>80)
        side = 2;
    let stepsfrom50 = Math.abs(80-coords['x']);
    let yardlnsfrom50 = stepsfrom50/8;
    let outstring = "";
    if (side!==0)
    {
        outstring = outstring.concat("Side ",side," - ");
    }
    let ydlines = Math.floor(yardlnsfrom50);
    let fractionydlines = Math.round(stepsfrom50 % 8*10)/10;

    if (fractionydlines === 0)
        outstring = outstring.concat("On ",50 - ydlines*5," Yard Line");
    else
    {
        if (fractionydlines > 4)
            ydlines+=1;
        outstring = outstring.concat(fractionydlines <= 4?fractionydlines:8-fractionydlines, " Steps ",fractionydlines <= 4?"Outside ":"Inside ",50 - ydlines*5," Yard Line");
    }
    let outstringy = "";
    if (coords["y"] < 0)
        {
            outstringy = outstringy.concat(Math.abs(coords["y"])," in front of Front Sideline");
        }
    else
    {
        let hashcount = Math.floor(coords['y']/28);
        let hashpartial = coords['y']%28;
        let infrontof = false;
        if (hashpartial ===0)
        {
            outstringy = "On " + hashes[hashcount];
        }
        else 
        {
            if (hashpartial > 14)
            {
                hashcount+=1;
                hashpartial=28-hashpartial;
                infrontof = true;
            }
            hashpartial = Math.round(hashpartial*10)/10;
            outstringy = outstringy.concat(hashpartial," steps ",infrontof?"in Front of ":"behind ",hashes[hashcount])
        }
    }
    return(<Fragment>{outstring},<br />{outstringy}</Fragment>);

}



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: "100%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

function Marcher(props)
{
    const pps = 40;
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    let me = props.me;
    const section = props.sectionData["s"];
    const perf = props.sectionData["p"];
    let set1valleft= 0;
    let set1valtop=0;
    let xt=0;
    let yt=0;
    let nickname = props.performers[section+perf];
    if (props.performers[section+perf] !== undefined && props.performers[section+perf].length === 2 && props.performers[section+perf][0] !== "" && props.performers[section+perf][0] !== null)
    {
      nickname = props.performers[section+perf][0];
      console.log(nickname);
    }
    else
    {
      nickname = undefined;
    }
    
    set1valleft=(pps*160)-pps*props.setData["dots"][0]['x']-pps;
    set1valtop=pps*props.setData["dots"][0]['y'];
    xt = (pps*160)-(pps*props.setData["dots"][props.currentset-1]['x']) -(pps/2) - set1valleft;
    yt = (pps*props.setData["dots"][props.currentset-1]['y'])-set1valtop-(pps/2);
    if (!props.mview)
    {
        set1valleft=(pps*160)-set1valleft-pps;
        set1valtop=(pps*84)-set1valtop-pps;
        xt = -1*xt;
        yt = -1*yt;
    }
    return(<Fragment><Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {section+perf} - {nickname}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {coordstotext(props.setData["dots"][props.currentset-1])}
          </Typography>
        </Box>
      </Modal>
      <Pressable onTap={handleOpen}><div  style={{position:"absolute",width:"40",height:"40px",top:set1valtop,left:set1valleft,transform: "translate(" + xt + "px, " + yt + "px)",transition: "all 0.5s"}} key={me+"div"}>
        <div key={me+"span"} style={{transform: props.thisperf===props.me["s"]+props.me["p"]?"rotate(-45deg)":"",position:"absolute",fontSize:"25px",fontWeight:"bold",color:pickfontcolor(props.sectionData["c"],"#FFFFFF","#000000"),verticalAlign:"middle",textAlign:"center",height:"40px",width:"40px",backgroundColor:props.sectionData["c"],borderRadius:props.thisperf===props.me["s"]+props.me["p"]?"3%":"50%",display:"block"}}>{props.thisperf===props.me["s"]+props.me["p"]?"":section+perf}</div>
        {props.thisperf===props.me["s"]+props.me["p"]?<div key={me+"textspan"} style={{position:"absolute",fontSize:"27px",verticalAlign:"middle",textAlign:"center",height:"40px",width:"40px",display:"block",color:pickfontcolor(props.sectionData["c"],"#FFFFFF","#000000")}}>ME</div>:""}
        <div key={me+"innerdiv"}style={{position:"absolute",top:"40px"}}><p key={"MarcherFlag+me"} style={{margin:"0px",textAlign:"center",color:"gray",fontSize:"25px",fontWeight:"bold", textShadow:"0.05em 0 white,0 0.05em white,-0.05em 0 white,0 -0.05em white,-0.05em -0.05em white,-0.05em 0.05em white,0.05em -0.05em white,0.05em 0.05em white"}}>{nickname === undefined ? "" : nickname}</p></div>
        
        </div>
        </Pressable>
        </Fragment>);
}

export default memo(Marcher);

