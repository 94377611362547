import { memo } from "react";

function XCounts(props)
{
    if (props.mydata === null)
        return "";
    let xdist,currentcounts,currentx,currenty,nextx,nexty;
    let xcrossings = [];
    if (props.cSet-1 < props.mydata['dots'].length-1)
    {
        let currentset = props.mydata['dots'][props.cSet-1];
        currentx = 160-currentset['x'];
        currenty = currentset['y'];
        //currentcounts = currentset['counts'];
        if (props.exdata !== null)
        currentcounts = props.exdata[props.cSet-1].motion_counts;
        else
        currentcounts=0;
        
        let nextset = props.mydata['dots'][props.cSet];
        nextx = 160-nextset['x'];
        nexty = nextset['y'];
        xdist = nextx-currentx;
        if (props.mode)
        {
            let xiter = currentx;
            console.log(xdist,xiter);
            while ((xdist<0 && xiter>nextx)||(xdist>0 && xiter<nextx))
            {
                if (xdist<0)
                {
                
                    let xmod = xiter % 4;
                    let disttonext = (4-xmod)
                    if (xiter-disttonext >= nextx)
                    {
                        console.log(disttonext);
                        xcrossings.push(disttonext);
                        
                    }
                    xiter-=disttonext;
                }
                else
                {
                    let xmod = xiter % 4;
                    let disttonext = (4-xmod)
                    if (xiter+disttonext <= nextx)
                    {
                        console.log(disttonext);
                        xcrossings.push(disttonext);
                        
                    }
                    xiter+=disttonext;
                }
            }
    
            console.log(xcrossings);
            let stepspercount = xdist/currentcounts;
            let beatcrossings = xcrossings.map((xcr)=>xcr/stepspercount);
            let beatsum = 0;
            let retval = "";
            console.log(beatcrossings);
            for (let i=0;i<beatcrossings.length;i++)
            {
                beatsum += Math.abs(beatcrossings[i]);
                retval+= Math.round(10*beatsum)/10+","
            }
            return "xCounts:" + retval.substring(0,retval.length-1);
        }
        else{
            let ystep = 17.75/28;
            let xstep = 5/8;
            let xdistance = Math.abs(currentx-nextx)*ystep;
            let ydistance = Math.abs(currenty-nexty)*xstep;
            let hypot = Math.sqrt(xdistance**2+ydistance**2);
            let stepsize=hypot/currentcounts;
            console.log("hypotmode");
            if (stepsize === 0)
                return "HOLD";
            let calcsize=  5/stepsize;
            if (currentcounts === 0)
                return "HOLD";
            return "Step Size:" + Math.round(10*calcsize)/10 + " to 5";
        }
    }
}
export default memo(XCounts);