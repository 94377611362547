import { Fragment, memo} from 'react';
export default memo(Canvas);


function Canvas(props) {
let mydata = "";
if (props.me['p'] !== "" && props.setdata !== null)
  mydata = props.setdata[props.me['s']]['perfs'][props.me['p']];

let fromline = null;
let toline = null;
if (mydata !== ""){
  if (props.currentset > 1){
    let width = 40*(mydata["dots"][props.currentset-1]['x'] - mydata["dots"][props.currentset-2]['x']);
    let height = 40*(mydata["dots"][props.currentset-1]['y']-mydata["dots"][props.currentset-2]['y']);
    let left =6400-40*(mydata["dots"][props.currentset-2]['x']<mydata["dots"][props.currentset-1]['x']?mydata["dots"][props.currentset-1]['x']:mydata["dots"][props.currentset-2]['x']);
    let top = 40*(mydata["dots"][props.currentset-2]['y']>mydata["dots"][props.currentset-1]['y']?mydata["dots"][props.currentset-1]['y']:mydata["dots"][props.currentset-2]['y']);

    fromline = <div style={{overflow:"visible",position:"absolute",backgroundColor:height===0||width===0?"red":"",top:top,left:left,height:height===0?8:Math.abs(height),width:width===0?8:Math.abs(width)}}>
                  {width !==0 && height!==0?
                  <svg style={{overflow:"visible"}} height={Math.abs(height)} width={Math.abs(width)} xmlns="http://www.w3.org/2000/svg">
                  <line key="fromline" 
                    style={{strokeWidth:"8px"}} 
                    x1={width > 0?0:Math.abs(width)}
                    y1={height < 0?0:Math.abs(height)}
                    x2={width < 0?0:Math.abs(width)} 
                    y2={height > 0?0:Math.abs(height)} stroke="red"/>
                </svg>:""}</div>;
  }
  if (props.currentset < mydata["dots"].length){
    let width2 = 40*(mydata["dots"][props.currentset-1]['x'] - mydata["dots"][props.currentset]['x']);
    let height2 = 40*(mydata["dots"][props.currentset-1]['y']-mydata["dots"][props.currentset]['y']);
    let left2 =6400-40*(mydata["dots"][props.currentset]['x']<mydata["dots"][props.currentset-1]['x']?mydata["dots"][props.currentset-1]['x']:mydata["dots"][props.currentset]['x']);
    let top2 = 40*(mydata["dots"][props.currentset]['y']>mydata["dots"][props.currentset-1]['y']?mydata["dots"][props.currentset-1]['y']:mydata["dots"][props.currentset]['y']);

    toline = <div style={{overflow:"visible",position:"absolute",backgroundColor:height2===0||width2===0?"green":"",top:top2,left:left2,height:height2===0?8:Math.abs(height2),width:width2===0?8:Math.abs(width2)}}>
                  {width2 !==0 && height2!==0?
                  <svg style={{overflow:"visible"}} height={Math.abs(height2)} width={Math.abs(width2)} xmlns="http://www.w3.org/2000/svg">
                  <line key="fromline" 
                    style={{strokeWidth:"8px"}} 
                    x1={width2 > 0?0:Math.abs(width2)}
                    y1={height2 < 0?0:Math.abs(height2)}
                    x2={width2 < 0?0:Math.abs(width2)} 
                    y2={height2 > 0?0:Math.abs(height2)} stroke="green"/>
                </svg>:""}</div>;
    //toline = <line key="toline" style={{strokeWidth:"4px"}} x1={6400-40*mydata["dots"][props.currentset]['x']} y1={40*mydata["dots"][props.currentset]['y']} x2={6400-40*mydata["dots"][props.currentset-1]['x']} y2={40*mydata["dots"][props.currentset-1]['y']} stroke="green"/>;
  }
}

return (<Fragment>
  {fromline}{toline}</Fragment>);
}