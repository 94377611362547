import { Fragment, memo} from 'react';
import Marcher from './Marcher';
function PlayField(props)
{
    let marchers = [];

    for (let section in props.setdata) {
        for (let perf in props.setdata[section]["perfs"])
        {
            marchers.push(<Marcher me={props.me} performers={props.performers} mview={props.mview} key={section+perf} thisperf={section+perf} sectionData={{"s":section,"p":perf,"c":props.setdata[section]["color"]}} currentset={props.currentset}setData={props.setdata[section]["perfs"][perf]}></Marcher>)
        }
    }

    return(<Fragment>{marchers}</Fragment>)
}

export default memo(PlayField);