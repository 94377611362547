import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
export default function SelectSmall(props) {
  const [section,setSection] = React.useState(props.me['s']);
  const [perfy,setPerformer] = React.useState(props.me['p']);
  const [name,setName] = React.useState("");
  const handleChange = (event) => {
    let tempmarcher = props.me;
    tempmarcher['s'] = event.target.value;
    props.setMarcher({'s':section,'p':event.target.value});
    
    setPerformer(event.target.value);
    if (props.performers[section+event.target.value] !== undefined && props.performers[section+event.target.value].length === 2 && props.performers[section+event.target.value][0] !== "" && props.performers[section+event.target.value][0] !== null)
    {
    setName(props.performers[section+event.target.value][0]);
    }
    else
    {
      setName("");
    }
    localStorage.setItem("marcher", JSON.stringify({'s':section,'p':event.target.value}));
  };
  const setmrname = (event) => {
    setName(event.target.value);
  };
  async function updatename (){
    console.log("bloop");
    const { data, error } = await props.supa.from('performers').upsert({full_name: section+perfy, prop_nickname: name},{ onConflict: 'full_name' }).select();
    console.log(data,error);
    props.closeme();
  }
  const handlesectionChange = (event) => {
    if (event.target.value === "dir")
    {
      props.closeme();
      props.setMarcher({'s':"dir",'p':""});
      localStorage.setItem("marcher", JSON.stringify({'s':"dir",'p':""}));
    }
    setPerformer("");
    setSection(event.target.value);
  };
  let sections = Object.keys(props.setdata);

  let perfs = [];
  if (section !== "dir")
  {
    perfs = Object.keys(props.setdata[section]['perfs']);
  }
  return (
    <React.Fragment>
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <InputLabel id="section-label">Section</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={section}
        label="Sections"
        onChange={handlesectionChange }
      >
        <MenuItem key="sectdir" value="dir">
          <em>dir</em>
        </MenuItem>
        {sections.map(section => {return (<MenuItem key={"sect"+section} value={section}>{section}</MenuItem>);})}

      </Select>
    </FormControl>
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
    <InputLabel id="demo-select-small-label">Performer</InputLabel>
    <Select
      labelId="demo-select-small-label"
      id="demo-select-small"
      value={perfy}
      label="Performer"
      onChange={handleChange}
    >
      {perfs.map(perf => {return (<MenuItem key={"perf"+perf} value={perf}>{perf}</MenuItem>);})}

    </Select>
  </FormControl>
  <FormControl>
    <TextField id="filled-basic" value={name} onChange={setmrname} style = {{textAlign:"center",marginLeft:"auto",marginRight:"auto",width: "80%"}}  label="Nickname" variant="standard" />
  </FormControl>
    <Button onClick={updatename} >Submit</Button>
  </React.Fragment>
  );
}