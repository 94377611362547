import PlayField from "./PlayField";
import Canvas from "./Canvas";
import React from "react";
import closeupgrid from "../assets/closeupgrid.png";
import fargrid from "../assets/fargrid.png";
import num_and_hash from "../assets/num_and_hash.png"
function FootballField(props)
{
  let mview = !(props.me["s"] === "dir")
  
  return(
  <div>
        {props.vp?<div id={"Bloop"} style={{width:6400,height:1680*2,position:"fixed",top:0,left:0,backgroundImage:`url(${closeupgrid})`}}></div>:<div id={"Bloop2"} style={{width:6400,height:1680*2,position:"fixed",top:0,left:0,backgroundImage:`url(${fargrid})`}}></div>}
        <div id={"Bloop2"} style={{width:6400,height:1680*2,position:"fixed",top:0,left:0,backgroundImage:`url(${num_and_hash})`}}></div>
        <Canvas currentset={props.currentset} setdata = {props.setdata} me={props.me}></Canvas>
        <PlayField performers={props.performers} mview={mview} me={props.me} setdata = {props.setdata} currentset={props.currentset}></PlayField>
        
  </div>
);
}

export default React.memo(FootballField);