import { useRef, useState, Fragment} from 'react'
import { Space, suppressBrowserZooming } from 'react-zoomable-ui';
import AppBar from '@mui/material/AppBar';
import FootballField from "./comps/FootballField";
import Toolbar from '@mui/material/Toolbar';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import './App.css'
import { FormControl, IconButton, Typography } from '@mui/material';
import { SpeedInsights } from "@vercel/speed-insights/react"
import SelectSmall from './comps/SelectSmall';
import XCounts from './comps/XCounts';
import useScreenSize from './comps/useScreenSize';
import MainDrawer from './comps/MainDrawer';
import CachedIcon from '@mui/icons-material/Cached';
import { createClient } from '@supabase/supabase-js'
import { executeQuotaErrorCallbacks } from 'workbox-core/_private';

// Create a single supabase client for interacting with your database
const supabase = createClient('https://wjsbhonpcmkaaguquwhh.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Indqc2Job25wY21rYWFndXF1d2hoIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjE0NzY3NjIsImV4cCI6MjAzNzA1Mjc2Mn0.OOFUG0stZEL8cn4u_UJvAhtXFoFbtXuqPjlllLSPWh0');

function SimpleDialog(props) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Who are you?</DialogTitle>
        <SelectSmall me={props.me} performers={props.performers} supa={supabase} closeme={handleClose} setMarcher={props.setMarcher} setdata={props.data}/>
        
    </Dialog>
  );
}


  


function App() {
  React.useEffect(suppressBrowserZooming);
  const [performers, setPerformers] = useState(() => {
    // getting stored value
  const saved = localStorage.getItem("performers");
  const initialValue = JSON.parse(saved);
  return initialValue;}
);
  const [datadata, setShowData] = useState(() => {
    // getting stored value
  const saved = localStorage.getItem("setdata");
  const initialValue = JSON.parse(saved);
  return initialValue;
});
const [extendedSetData, setExtendedSetData] = useState(() => {
  // getting stored value
const saved = localStorage.getItem("extendedsetdata");
const initialValue = JSON.parse(saved);
return initialValue;
});
  const [mydata, setMyData] = useState(null);

  React.useEffect(() => {
    
    if (performers == null)
      {
    getPerformers();
      }
    if (datadata == null || extendedSetData == null)
    {
      getData();
    }
    else{
      if (me['s'] !== "dir")
        {
        setMyData(datadata[me['s']]["perfs"][me['p']]);
      }
      else
        setMyData(Object.values(Object.values(datadata)[0]["perfs"])[0]);
    }
  }, []);

  async function getPerformers() {
    const { data } = await supabase.from("performers").select();
    let perfdata = {};
    for (let i=0;i<data.length;i++)
    {
      perfdata[data[i]['full_name']] = [data[i]['nickname'],data[i]['id']];
    }
    setPerformers(perfdata);
    console.log(performers);
    localStorage.setItem("performers", JSON.stringify(perfdata));
  } 
  async function getData() {
    const { data,error } = await supabase.from("songs").select();
    console.log(data);
    setShowData(data[0]['songdata']);
    if (me['s'] !== "dir")
      setMyData(data[0]['songdata'][me['s']]["perfs"][me['p']]);
    else
      setMyData(Object.values(Object.values(data[0]['songdata'])[0]["perfs"])[0]);
    localStorage.setItem("setdata", JSON.stringify(data[0]['songdata']));
    let value = await supabase.from("setinfo").select();
    setExtendedSetData(value.data);
    console.log(value.data,value.error);
    localStorage.setItem("extendedsetdata", JSON.stringify(value.data));

    
  } 


  const screenSize = useScreenSize();
  const [open, setOpen] = React.useState(false);
  const [draweropen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setDrawerOpen(newOpen);
  };
  const [me, setthisMarcher] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("marcher");
    const initialValue = JSON.parse(saved);
    return (saved != null) ? initialValue:{"s":"dir","p":""};
  });
  const r = useRef(null);
  const [viewp, setVp] = useState(null);
  const [thisvp, setthisVp] = useState(null);
  const [cSet, setCset] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("currentset");
    const initialValue = JSON.parse(saved);
    return (saved != null) ? initialValue:1;
  });
  const [xCountMode, setxCountMode] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("xcount");
    const initialValue = JSON.parse(saved);
    return (saved != null) ? initialValue: false;
  });
  
  const handleRefresh = () => {
    getPerformers();
    getData();
  }
  const setMarcher = (input) => {
    
    if (input['s'] !== "dir")
      setMyData(datadata[input['s']]["perfs"][input['p']]);
    else
      setMyData(Object.values(Object.values(datadata)[0]["perfs"])[0]);
    setthisMarcher(input);
  };
  const initViewPort = (vp) => {
      setthisVp(vp);
      let heightratio = screenSize['height']/4260;
      let widthratio = screenSize['width']/7500;
      let widthbound = 7500;
      let heightbound = 4260;
      if (heightratio < widthratio)
        widthbound = 4260*screenSize['width']/screenSize['height'];
      else
        heightbound = 7500*screenSize['height']/screenSize['width'];

      let widthoffset = Math.abs(widthbound - 6400)/2
      let heightoffset = Math.abs(heightbound - 3360)/2;

      vp.setBounds({ x: [0-widthoffset, 6400+widthoffset], y: [0-heightoffset, 3360+heightoffset] });
      if (me['s'] !== "dir")
        {
        setMyData(datadata[me['s']]["perfs"][me['p']]);
      
        let pps = 40;
        let set1valleft= 0;
        let set1valtop=0;
        let xt=0;
        let yt=0;        
        set1valleft=(pps*160)-pps*datadata[me['s']]["perfs"][me['p']]["dots"][0]['x']-pps;
        set1valtop=pps*datadata[me['s']]["perfs"][me['p']]["dots"][0]['y'];
        xt = (pps*160)-(pps*datadata[me['s']]["perfs"][me['p']]["dots"][cSet-1]['x']) -(pps/2) - set1valleft;
        yt = (pps*datadata[me['s']]["perfs"][me['p']]["dots"][cSet-1]['y'])-set1valtop-(pps/2);
        console.log(set1valleft+xt,set1valtop+yt);
        vp.camera.recenter(set1valleft+xt,set1valtop+yt)
      }
     // vp.camera.centerFitHorizontalAreaIntoView({
     //   left: 0,
     //   width: 6400,
     // });
    
    }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const incSet = () => {
    if (cSet < (mydata===null?0:mydata['dots'].length))
    {
    setCset(cSet+1);
    localStorage.setItem("currentset",cSet);
    if (me['s'] !== "dir")
      {     
      let pps=40;
      //let width = 40*(mydata["dots"][cSet-1]['x'] - mydata["dots"][cSet-2]['x']);
      //let height = 40*(mydata["dots"][cSet-1]['y']-mydata["dots"][cSet-2]['y']);
      //console.log(width,height);
      //thisvp.camera.moveBy(-width,-height);
      let set1valleft=(pps*160)-pps*mydata["dots"][0]['x']-pps;
      let set1valtop=pps*mydata["dots"][0]['y'];
      let xt = (pps*160)-(pps*mydata["dots"][cSet]['x']) -(pps/2) - set1valleft;
      let yt = (pps*mydata["dots"][cSet]['y'])-set1valtop-(pps/2);
      thisvp.camera.recenter(set1valleft+xt,set1valtop+yt,undefined,{durationMilliseconds:600,preventInterruption:true});
    }
    }
  };
  const decSet = () => {
    if (cSet > 1)
    {
    setCset(cSet-1);
    localStorage.setItem("currentset",cSet);
    if (me['s'] !== "dir")
      {     
      let pps=40;
      //let width = 40*(mydata["dots"][cSet-1]['x'] - mydata["dots"][cSet-2]['x']);
      //let height = 40*(mydata["dots"][cSet-1]['y']-mydata["dots"][cSet-2]['y']);
      //console.log(width,height);
      //thisvp.camera.moveBy(-width,-height);
      let set1valleft=(pps*160)-pps*mydata["dots"][0]['x']-pps;
      let set1valtop=pps*mydata["dots"][0]['y'];
      let xt = (pps*160)-(pps*mydata["dots"][cSet-2]['x']) -(pps/2) - set1valleft;
      let yt = (pps*mydata["dots"][cSet-2]['y'])-set1valtop-(pps/2);
      thisvp.camera.recenter(set1valleft+xt,set1valtop+yt,undefined,{durationMilliseconds:600,preventInterruption:true});
    }
      
  }
  };

  const handleClickxCount = () => {
    localStorage.setItem("xcount",!xCountMode);
    setxCountMode(!xCountMode);
    
  };
  const getSetExtendedString = () => {
    var setnum = cSet-1;
    if (extendedSetData !== null)
    var string = extendedSetData[setnum].SubSetInfo;
    else
    var string = "";
    return string;
  };
  const extractnumberfromstring = (inputstring) => {
      let matches = inputstring.matchAll(/(\d+)/g);
      let outputnum = 0;
      // Display output if number extracted
      for (const match of matches) {
          outputnum += parseInt(match[0]);
      }
      console.log(outputnum);
      return outputnum;
  };

  
  ///*=<MenuItem key={"menu"} onClick={() => cSet > 1 ? setDrawerOpen(true) : ""}>MENU</MenuItem>
  return (
    <Fragment>
      <SimpleDialog
        open={open}
        onClose={handleClose}
        setMarcher={setMarcher}
        me={me}
        data={datadata}
        performers={performers}
      />
      <SpeedInsights/>
      <MainDrawer open={draweropen} toggleDrawer={toggleDrawer}></MainDrawer>
      <AppBar position="fixed" color="primary" sx={{ top: 0, bottom: 'auto' }}>
      <Toolbar variant="dense">
      <Typography>Set: {cSet} </Typography>
      <MenuItem key={"counts"}>
      <Typography>Counts: {extractnumberfromstring(getSetExtendedString())} </Typography>
      </MenuItem>
      <MenuItem key={"countstructure"}>
      <Typography>{getSetExtendedString()} </Typography>
      </MenuItem>
      {me['s']!=="dir"?<MenuItem key={"xCounts"} onClick={handleClickxCount}><Typography><XCounts exdata={extendedSetData} mode={xCountMode} mydata={mydata} cSet={cSet}/> </Typography></MenuItem>:""}
      </Toolbar>
      </AppBar>
    <Space ref={r} onCreate={vp => initViewPort(vp)} onUpdated={vp => setVp(vp.zoomFactor)} style={{ backgroundColor: 'white' }}>
      
      <FootballField performers={performers} me={me} currentset={cSet} setdata={datadata} vp={viewp>=.4} dimensions={screenSize}></FootballField>
      
    </Space>
    <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
      <Toolbar variant="dense">
      <IconButton onClick={handleRefresh}edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
            <CachedIcon />
      </IconButton>
      <MenuItem key={"marcher"} onClick={handleClickOpen}>{me['s']+me['p']}</MenuItem>
      
      <MenuItem key={"minus"} onClick={decSet}>-</MenuItem>
      <MenuItem key={"plus"} onClick={incSet}>+</MenuItem>
      
      
      </Toolbar>
      </AppBar>
      
    </Fragment>
  );
    
  
}


export default App
